import * as React from "react"
import { graphql } from "gatsby"
import FAQCard from "../components/FAQCard"
import SymptomsCard from "../components/SymptomsCard"
import AdmissionGreetingCard from "../components/AdmissionGreetingCard"
import LeadForm from "../components/Contact"

import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

const WhatToExpectTemplate = ({ pageContext, data }) => {
  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
        }
      ]
    }}>
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]['headerTitle']}
        imageUrl={data.firestore.page.content.headerImage}
      />
      <main className="my-12 flex flex-col justify-evenly w-full md:flex-row">
        <article
          className="prose-sm lg:prose mx-8 mb-16 lg:mb-0"
          dangerouslySetInnerHTML={{__html: data.firestore.page.content[pageContext.locale]['html']}}>
        </article>
        <aside className="items-center flex flex-col space-y-6 lg:items-start">
          <AdmissionGreetingCard/>
          <SymptomsCard/>
          <FAQCard/>
        </aside>
      </main>
      <LeadForm locale={pageContext.locale} />
    </Layout>
  )
}

export default WhatToExpectTemplate

export const query = graphql`
  query WhatToExpectTemplateQuery($pageId: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
    }
  }
`